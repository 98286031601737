import React from "react"
import Helmet from 'react-helmet';
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import { Link } from "gatsby"

const IndexPage = ({
  data: {
    site,
    allMarkdownRemark: { edges },
  },
}) => {


  return (
    <>
      <Layout>
        <Helmet>
          <title>{site.siteMetadata.title}</title>
          <meta name="description" content={site.siteMetadata.description} />
        </Helmet>
        <div className="headline">Notion's little brother <span role="img" aria-label="happy">😃</span></div>
        <div className="primary-content">There are as many use cases of Notion as people.<br />
        Find curated apps and operating systems to power up your Notion experience.</div>
        {/* <h2>Blog Posts &darr;</h2> */}

        <div className="grids0">
          <article className="card ">
              <Link to="apps">
                  <img src={'/app.webp'} alt="Notionful Apps" />
              </Link>
              <header>
                <h2 className="post-title">
                  <Link to="apps" className="post-link">
                    Apps
                  </Link>
                </h2>
              </header>
            </article>
          <article className="card ">
              <Link to="os">
                  <img src={'/os.png'} alt="Notionful OS" />
              </Link>
              <header>
                <h2 className="post-title">
                  <Link to="os" className="post-link">
                    Operating Systems
                  </Link>
                </h2>
              </header>
            </article>
        </div>
      </Layout>
      <footer className="site-footer">
        <p>
          &copy; {new Date().getFullYear()} Notionful &bull; 
          Eagerly waiting to build on top of Notion API <span role="img" aria-label="wait">😇 | Send your projects at hey@notionful.com</span>
        </p>
      </footer>
  </>
  )
}

export default IndexPage
export const pageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(filter: {frontmatter: {path: {regex: "/new/"}}}, sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            thumbnail
          }
        }
      }
    }
  }
`
